import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';

import Header from '../components/layouts/header';
import Layout from '../components/layouts/main';
import SEO from '../components/layouts/seo';

const classes = {
  title: 'mt-16 text-4xl text-gray-900 dark:text-white font-bold',
  date: 'text-gray-600 dark:text-gray-400 font-light',
  wrapper: 'mt-8 blog-content',
};

const BlogPost = ({ data, location }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <Header metadata={data.site.siteMetadata} />
      <SEO
        location={location}
        isArticle={true}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        meta={[{
          property: "article:published_time",
          content: moment(post.frontmatter.date).utc().toISOString()
        }, {
          property: "article:author",
          content: `https://rhuzaifa.com/` // this should point to a public profile page of author
        },
        // TODO:
        // check if these tags will help
        // - `article:section`
        // - `article:tags`
        // {
        //   name: `keywords`,
        //   content: `keyword1, keyword2, keyword3`,
        // },
        // - add json ld
      ]}
      />
      <h1 className={classes.title}>{post.frontmatter.title}</h1>
      <p className={classes.date}>
        Posted on {moment(post.frontmatter.date).format('MMMM D, YYYY')}
      </p>
      <div
        className={classes.wrapper}
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        name
        title
        description
        about
        twitter
        github
        linkedin
        devto
        stackoverflow
        leetcode
        discord
        email
        projects {
          name
          description
          link
          github
        }
        experience {
          name
          description
          link
        }
        skills {
          name
          description
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
